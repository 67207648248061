import React from "react";
import './Home.css';

function Home() {
  return (
    <div  className="home-header">
      <div className="title">
        <h1>Work</h1>
        <h1>In</h1>
        <h1>Progress</h1>
      </div>
    </div>
  );
}

export default Home;