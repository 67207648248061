import React from "react";

function Parallel() {
  return (
    <div >
        <body className="App-header">
          <h2>Parallel Planetfall Match Statistics</h2>
          <p><iframe title="Parallel Planetfall" width="1280" height="765" src="https://app.powerbi.com/view?r=eyJrIjoiMjcwYTJiNjItYTBiMS00NjczLThhNjgtYjEyZWRjMjdkMzcxIiwidCI6IjRhNDI4NzQ0LWQ1MDgtNDI1YS1iM2Q1LTQ0Y2Q1YjkwYjkzOSIsImMiOjZ9&pageName=254b8012b2e7084b7e60" frameborder="0" allowFullScreen="true"></iframe></p>
        </body>
    </div>
  );
}

export default Parallel;